import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
	VictoryLine,
	VictoryChart,
	VictoryTheme,
	VictoryArea,
	VictoryAxis
} from 'victory';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import NProgress from 'nprogress';

import ExportIcon from '../img/export-icn.svg';

// colors
const good = '#00e400';
const moderate = '#ffff00';
const hazardous = '#7e0023';
const gray = '#e4e4e4';
const chartWidth = 500;
const chartHeight = 250;

const currentMonth = dayjs().month();
const initialAQIState = {
	lastMonth: [
		{ day: 1, aqi: null },
		{ day: 30, aqi: null }
	],
	thisMonth: [
		{ day: 1, aqi: null },
		{ day: 30, aqi: null }
	]
};

const getMonthStartEnd = (initialMonth, monthsBack = 0) => ({
	start_date: dayjs()
		.month(initialMonth)
		.subtract(monthsBack, 'months')
		.startOf('month')
		.format('YYYY-MM-DD'),
	end_date: dayjs()
		.month(initialMonth)
		.subtract(monthsBack, 'months')
		.endOf('month')
		.format('YYYY-MM-DD')
});

// filter aqis: if a day has multiple aqis, return only the highest one
const filterAQIs = (aqis, isStation) =>
	aqis && aqis.length > 0
		? Object.entries(
				aqis.reduce((acc, cur) => {
					acc[cur.date_observed] = acc[cur.date_observed]
						? Math.max(acc[cur.date_observed], cur.aqi)
						: cur.aqi;
					return acc;
				}, {})
		  ).map(([date, aqi]) => ({ day: dayjs(date).date(), aqi }))
		: initialAQIState.lastMonth;

// calc chart's max Y domain
const getMaxAQI = aqis =>
	aqis.map(r => r.aqi).reduce((acc, cur) => Math.max(acc, cur), 0);
const roundUp = num => (num > 0 ? Math.ceil(num / 80) * 80 : 100);

const createCSV = (data, fields, type) => {
	let csv = '';

	const replace = {
		O3: 'OZONE'
	};

	csv += fields.join(',');

	for (const item of data[type]) {
		let line = [];
		for (const field of fields) {
			let val = null;

			if (item.hasOwnProperty(field)) {
				val = item[field];
			} else if (item.parameter && item.parameter[field]) {
				val = item.parameter[field];
			}

			if (replace.hasOwnProperty(val)) {
				val = replace[val];
			}

			if (val === null || val === '') {
				val = 'NA';
			}

			line.push(val);
		}

		csv += '\n' + line.join(',');
	}

	return URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
};

const createAQIExportFile = (data, isStation) => {
	let fields = isStation
		? ['date_observed', 'aqi', 'aqs_site_id', 'name']
		: ['date_observed', 'aqi', 'name'];

	return createCSV(data, fields, 'aqi');
};

const createReadingsExportFile = data => {
	let fields = [
		'date',
		'hour',
		'report_value',
		'aqs_method_code',
		'parameter_code',
		'parameter_desc',
		'parameter_name',
		'parameter_category_name',
		'unit_description',
		'unit_abbreviation'
	];

	return createCSV(data, fields, 'readings');
};

export default function HistoryGraphPanel({ stationId = null }) {
	const [readings, setReadings] = useState(null);
	const [aqis, setAQIs] = useState(initialAQIState);
	const [unfilteredAQIs, setUnfilteredAQIs] = useState({});
	const [maxDomain, setMaxDomain] = useState(roundUp(0));
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);
	const [exportAQIFile, setExportAQIFile] = useState(null);
	const [exportReadingsFile, setExportReadingsFile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const isStation = stationId != null;
	const baseUrl = isStation
		? `${process.env.API_BASE_URL}/aqi/${stationId}`
		: `${process.env.API_BASE_URL}/aqi/history`;
	const readingsBaseUrl = isStation
		? `${process.env.API_BASE_URL}/readings/${stationId}`
		: null;

	//Load historical Readings data (for export)
	useEffect(() => {
		const fetchData = month => {
			const getLastMonthData = () =>
				axios.get(readingsBaseUrl, {
					params: getMonthStartEnd(month, 1)
				});
			const getThisMonthData = () =>
				axios.get(readingsBaseUrl, {
					params: getMonthStartEnd(month)
				});
			NProgress.start();
			if (!isLoading) {
				setIsLoading(true);
			}
			axios
				.all([getLastMonthData(), getThisMonthData()])
				.then(
					axios.spread((lastMonthData, thisMonthData) => {
						setReadings({
							lastMonth: lastMonthData.data.data.readings,
							thisMonth: thisMonthData.data.data.readings
						});
					})
				)
				.catch(err => {
					console.error(err);
					NProgress.done();
					setIsLoading(false);
				});
		};

		// Only load readings on the station page
		if (isStation) {
			fetchData(selectedMonth);
		}
	}, [readingsBaseUrl, selectedMonth]);

	//Load historical AQI data
	useEffect(() => {
		const fetchData = month => {
			const getLastMonthData = () =>
				axios.get(baseUrl, {
					params: getMonthStartEnd(month, 1)
				});
			const getThisMonthData = () =>
				axios.get(baseUrl, {
					params: getMonthStartEnd(month)
				});
			NProgress.start();
			if (!isLoading) {
				setIsLoading(true);
			}
			axios
				.all([getLastMonthData(), getThisMonthData()])
				.then(
					axios.spread((lastMonthData, thisMonthData) => {
						let initialAQIs = {
							lastMonth: lastMonthData.data.data,
							thisMonth: thisMonthData.data.data
						};

						let filteredAQIs = {
							lastMonth: filterAQIs(initialAQIs.lastMonth, isStation),
							thisMonth: filterAQIs(initialAQIs.thisMonth, isStation)
						};

						setAQIs({
							lastMonth: filteredAQIs.lastMonth,
							thisMonth: filteredAQIs.thisMonth
						});

						setUnfilteredAQIs({
							lastMonth: initialAQIs.lastMonth,
							thisMonth: initialAQIs.thisMonth
						});

						setMaxDomain(
							roundUp(
								Math.max(
									getMaxAQI(filteredAQIs.lastMonth),
									getMaxAQI(filteredAQIs.thisMonth)
								)
							)
						);

						NProgress.done();
						setIsLoading(false);
					})
				)
				.catch(err => {
					console.error(err);
					NProgress.done();
					setIsLoading(false);
				});
		};
		fetchData(selectedMonth);
	}, [baseUrl, selectedMonth]);

	useEffect(() => {
		if (unfilteredAQIs && unfilteredAQIs.thisMonth) {
			// We have all the data, create the export files
			setExportAQIFile(
				createAQIExportFile(
					{
						aqi: unfilteredAQIs.thisMonth
					},
					isStation
				)
			);
		}

		if (readings && readings.hasOwnProperty('thisMonth')) {
			setExportReadingsFile(
				createReadingsExportFile({
					readings: readings.thisMonth
				})
			);
		}
	}, [unfilteredAQIs, readings]);

	const renderAqiExport = () => {
		if (isStation) {
			return null;
		}

		return (
			<a
				className='export'
				data-disabled={!exportAQIFile ? 'true' : 'false'}
				download={exportAQIFile ? 'aqidata.csv' : null}
				href={exportAQIFile}
			>
				<span>Export AQI</span>
			</a>
		);
	};

	const renderReadingsExport = () => {
		if (!isStation) {
			return null;
		}

		return (
			<a
				className='export'
				data-disabled={!exportReadingsFile ? 'true' : 'false'}
				download={exportReadingsFile ? 'readings.csv' : null}
				href={exportReadingsFile}
			>
				<span>Export Readings</span>
			</a>
		);
	};

	const renderExportLinks = () => {
		return (
			<div className='export-links'>
				<ExportIcon />
				{renderAqiExport()}
				{renderReadingsExport()}
			</div>
		);
	};

	const renderChart = () => {
		if (isStation) {
			return null;
		}
		return (
			<VictoryChart
				domain={{ y: [0, maxDomain] }}
				theme={VictoryTheme.material}
				className='line-chart'
				width={chartWidth}
				height={chartHeight}
				style={{
					parent: {
						position: 'absolute',
						height: '100%',
						width: '100%',
						left: 0,
						top: 0
					}
				}}
				animate={{ duration: 1500 }}
			>
				<VictoryAxis
					dependentAxis
					style={{
						axis: { stroke: 'transparent' },
						axisLabel: {
							fontSize: 10,
							fill: gray,
							padding: 30
						},
						ticks: { stroke: 'transparent' },
						tickLabels: { fontSize: 8, fill: gray }
					}}
					label='AQI'
				/>
				<VictoryAxis
					style={{
						axisLabel: {
							fontSize: 10,
							fill: gray,
							padding: 25
						},
						tickLabels: { fontSize: 8, fill: gray }
					}}
					label='Day'
				/>
				<VictoryArea
					interpolation='natural'
					style={{ data: { fill: gray } }}
					data={aqis.lastMonth}
					x='day'
					y='aqi'
				/>
				<VictoryLine
					interpolation='natural'
					style={{
						data: { stroke: good },
						parent: { border: '1px solid #ccc' }
					}}
					data={aqis.thisMonth}
					x='day'
					y='aqi'
				/>
			</VictoryChart>
		);
	};

	return (
		<section className={`history-graph-panel ${isStation ? 'is-station' : ''}`}>
			<div className='header'>
				<h1>{isStation ? 'Station Data Look-up by Month' : 'AQI History'}</h1>
				{isStation || (
					<div className='labels'>
						<span className='month-label last-month' />
						<span>
							{dayjs()
								.month(selectedMonth)
								.subtract(1, 'month')
								.format('MMM YYYY')}
						</span>
						<span className='month-label this-month' />
						<span>
							{dayjs()
								.month(selectedMonth)
								.format('MMM YYYY')}
						</span>
					</div>
				)}
				<div className='header-right'>
					<DropdownButton
						className='dropdown'
						title={dayjs()
							.month(selectedMonth)
							.format('MMMM')}
					>
						{[...Array(12)].map((_, idx) => (
							<DropdownItem
								as='button'
								key={`month-${idx}`}
								eventKey={idx}
								onSelect={setSelectedMonth}
							>
								{dayjs()
									.month(idx)
									.format('MMMM')}
							</DropdownItem>
						))}
					</DropdownButton>
					{renderExportLinks()}
				</div>
			</div>
			<div
				className='error-message'
				data-visible={
					!isLoading &&
					aqis.lastMonth[0].aqi === null &&
					aqis.thisMonth[0].aqi === null
				}
			>
				No data is available for the selected months
			</div>
			{isStation || (
				<div
					style={{
						position: 'relative',
						height: 0,
						width: '100%',
						padding: 0,
						paddingBottom: `${100 * (chartHeight / chartWidth)}%`
					}}
				>
					{renderChart()}
				</div>
			)}
		</section>
	);
}
