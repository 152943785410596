import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tabs, Tab } from 'react-bootstrap';

import BasicView from '../views/BasicView';
import DetailView from '../views/DetailView';

const SectionHeader = ({ status, history, location: { pathname } }) => {
	let active = 'basic';

	if (pathname == '/' || pathname == '/basic') {
		active = 'basic';
	} else if (pathname == '/details') {
		active = 'details';
	} else {
		return null;
	}

	return (
		<span className={status ? `tabs-wrap ${status}` : 'tabs-wrap na'}>
			<Tabs
				activeKey={active}
				defaultActiveKey={active}
				onSelect={key => {
					history.push(`/${key}`);
				}}
			>
				<Tab eventKey='basic' title='The Basics' className='foo' />
				<Tab eventKey='details' title='More Detail' />
			</Tabs>
		</span>
	);
};

const mapStateToProps = store => {
	return {
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status'])
	};
};

export default connect(mapStateToProps)(withRouter(SectionHeader));
