import React from 'react';
import { Card, Collapse } from 'react-bootstrap';

export default class HowToHelpPanel extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			aboutPollutantsAccordionOpen: false,
			aboutDataAccordionOpen: false,
			helpAccordionOpen: false
		};
	}

	render() {
		const {
			aboutPollutantsAccordionOpen,
			aboutDataAccordionOpen,
			helpAccordionOpen
		} = this.state;

		return (
			<section className='how-to-help'>
				<h1>More Information</h1>
				<Card className='accordion'>
					<>
						<Card.Header
							onClick={() =>
								this.setState({
									aboutPollutantsAccordionOpen: !aboutPollutantsAccordionOpen
								})
							}
							aria-controls='accordion-collapse'
							aria-expanded={open}
							className={aboutPollutantsAccordionOpen ? 'open' : 'closed'}
						>
							<div className='title'> About the Pollutants </div>
						</Card.Header>
						<Collapse in={this.state.aboutPollutantsAccordionOpen}>
							<Card.Body>
								<Card.Text>Ozone (O3)</Card.Text>
								<Card.Text>
									People with lung diseases, such as asthma, chronic bronchitis,
									and emphysema, can be particularly sensitive to ozone. O3 can
									aggravate their diseases, leading to increased medication use,
									doctor and emergency room visits, and hospital admissions.
									Children, teens and active adults, are at higher risk from
									ozone exposure because they are often active outdoors in
									warmer weather when ozone levels are higher.
								</Card.Text>
								<Card.Text>Fine Particles (PM2.5)</Card.Text>
								<Card.Text>
									Because PM2.5 particles are so small, they can reach deep
									inside the lungs when inhaled and some may even get into the
									bloodstream, leading to a wide range of health problems. In
									sensitive populations, exposure to PM2.5 can trigger
									cardiovascular disease-related deaths from heart attack,
									stroke, arrhythmia, sudden cardiac arrest, and heart failure.
								</Card.Text>
								<Card.Text>Coarse Particles (PM10)</Card.Text>
								<Card.Text>
									When exposed to high levels of this larger particle pollution,
									older adults and people with existing lung disease may not be
									able to breathe as deeply or vigorously as they normally
									would. They may experience symptoms such as coughing and
									shortness of breath.
								</Card.Text>
								<Card.Text>Carbon Monoxide (CO)</Card.Text>
								<Card.Text>
									People with cardiovascular disease, such as coronary artery
									disease, may experience chest pain and other cardiovascular
									symptoms if they are exposed to carbon monoxide, particularly
									while exercising.
								</Card.Text>
								<Card.Text>Sulfur Dioxide (SO2)</Card.Text>
								<Card.Text>
									People with asthma are most likely to experience the health
									effects of sulfur dioxide. The main effect, even with very
									brief exposure (minutes), is a narrowing of the airways and
									may be accompanied by wheezing, chest tightness, and shortness
									of breath.
								</Card.Text>
								<Card.Text>Nitrogen Dioxide (NO2)</Card.Text>
								<Card.Text>
									Scientific evidence links short-term NO2 exposures, ranging
									from 30 minutes to 24 hours, with adverse respiratory effects
									including airway inflammation in healthy people and increased
									respiratory symptoms in people with asthma.
								</Card.Text>
								<Card.Text>Volatile Organic Compounds (VOCs)</Card.Text>
								<Card.Text>
									Volatile organic compounds (VOCs) are emitted as gases from
									certain solids or liquids and include a variety of chemicals.
									In addition to playing a role in the formation of ozone, some
									VOCs may also have short- and long-term adverse health
									effects.
								</Card.Text>
							</Card.Body>
						</Collapse>
					</>
				</Card>
				<Card className='accordion'>
					<>
						<Card.Header
							onClick={() =>
								this.setState({
									aboutDataAccordionOpen: !aboutDataAccordionOpen
								})
							}
							aria-controls='accordion-collapse'
							aria-expanded={open}
							className={aboutDataAccordionOpen ? 'open' : 'closed'}
						>
							<div className='title'> About the Data </div>
						</Card.Header>
						<Collapse in={this.state.aboutDataAccordionOpen}>
							<Card.Body>
								<Card.Text>
									Louisville Air Watch is the official ambient air quality
									information web map for the Louisville Metro Air Pollution
									Control District, an agency of Louisville Metro Government.
								</Card.Text>
								<Card.Text>
									In order to provide information that is as up-to-date as
									possible, data are not yet completely quality assured.
								</Card.Text>
							</Card.Body>
						</Collapse>
					</>
				</Card>
				<Card className='accordion'>
					<>
						<Card.Header
							onClick={() =>
								this.setState({
									helpAccordionOpen: !helpAccordionOpen
								})
							}
							aria-controls='accordion-collapse'
							aria-expanded={open}
							className={helpAccordionOpen ? 'open' : 'closed'}
						>
							<div className='title'> How You Can Help </div>
						</Card.Header>
						<Collapse in={this.state.helpAccordionOpen}>
							<Card.Body>
								<Card.Text>
									Visit the Kentuckiana Air Education (KAIRE) website for more
									tips on how you can help keep Louisville's air clean:
								</Card.Text>
								<Card.Text>
									<a href='https://helptheair.org/how-you-can-help/'>
										https://helptheair.org/how-you-can-help/
									</a>
								</Card.Text>
							</Card.Body>
						</Collapse>
					</>
				</Card>
			</section>
		);
	}
}
