import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { cleanSiteDescription } from '../modules/AirAPI/actions';
import { getAQIStrings } from '../modules/AQI/aqi';
import { getPollutantStrings } from '../modules/AQI/pollutants';
import StationMap from './StationMap';
import StationGraph from './StationGraph';
import StationDropdown from './StationDropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../img/tooltip.svg';

function StationMainPanel(props) {
	const {
		stationId,
		stationAqiData,
		overall_aqi,
		overall_status,
		overall_title,
		sites,
		state
	} = props;
	const { readings } = state;

	if (!stationId || !overall_status || !overall_aqi || !overall_title) {
		return null;
	}

	const getStationAQI = data => {
		if (!data || data.size <= 0) {
			return { aqi: 'NA', parameter_name: 'NA' };
		} else {
			return data.get(0).toJS();
		}
	};

	if (state.dataStatus === 'loaded') {
		let pollutantUnit = '';
		let pollutantName = '';

		const { aqi, parameter_name } = getStationAQI(stationAqiData);
		const stationStatus = aqi && aqi != 'NA' ? getAQIStrings(aqi).status : null;

		const pollutant_avg =
			parameter_name !== 'NA'
				? getPollutantStrings(parameter_name) &&
				  getPollutantStrings(parameter_name).avg
				: null;

		let report_value = null;

		//Figure out the report value of the top pollutant
		if (parameter_name != 'NA') {
			for (const reading of readings) {
				if (
					reading.parameter_name == parameter_name ||
					reading.air_now_mnemonic == parameter_name
				) {
					report_value = reading.report_value;
					break;
				}
			}
		}

		let pollutant_strings = getPollutantStrings(parameter_name);
		pollutantUnit = pollutant_strings ? pollutant_strings.unit : '';
		pollutantName = pollutant_strings ? pollutant_strings.name : '';
		return (
			<>
				<Helmet titleTemplate='%s | Louisville Air Watch'>
					<title>{cleanSiteDescription(state.site.site_description)}</title>
				</Helmet>
				<section className='station-main-panel'>
					<StationMap
						selected_station_id={state.site.aqs_site_id}
						selected_station={state.site}
					/>
					<div className='station-info'>
						<div className='station-info-header'>
							<h1>Station Data</h1>
							<StationDropdown selected_station={state.site} sites={sites} />
						</div>
						<div className='station-aqi'>
							<div className={`aqi-marker ${stationStatus}`}>
								<div className='aqi-marker-fg'>
									<p>AQI: {aqi}</p>
								</div>
							</div>
							<div className='top-pollutant'>
								<h2>Top Pollutant</h2>
								<p>{pollutantName}</p>
								<div className='pollutant-id-val'>
									<p className={`pollutant-id ${stationStatus}`}>
										{parameter_name}
									</p>
									<p className='pollutant-val'>
										{report_value}
										&nbsp;
										{pollutantUnit}
									</p>
								</div>
							</div>
						</div>
						<div className='compare-aqi'>
							<div className='skittle'>
								Compare it to Louisville’s AQI:
								<span className='skittle-val'>
									<img
										src={require(`../img/skittles/${overall_status.toLowerCase()}_skittle.png`)}
										alt={`${overall_status} air quality range`}
									/>
									<b> {overall_aqi}</b> &ndash;
									<span className={`fg-${overall_status}`}>
										&nbsp;{overall_title}
									</span>
								</span>
							</div>
						</div>
						<div className='station-graph'>
							<h3>
								{cleanSiteDescription(state.site.site_description)}
								&nbsp;Pollutant Breakdown
							</h3>
							<StationGraph aqi={state.aqi} />
						</div>
						<div className='station-about'>
							<h2>About This Site</h2>
							<p>
								<span className='about-lbl'>Site ID: </span>
								{state.site.aqs_site_id}
							</p>
							<p>
								<span className='about-lbl'>Description: </span>
								{state.site.site_description}
							</p>
							<p>
								<span className='about-lbl'>Address: </span>
								{state.site.street_address_1}
							</p>
						</div>
					</div>
				</section>
			</>
		);
	} else {
		return <div>Loading...</div>;
	}
}

const mapStateToProps = (store, { match, state }) => {
	return {
		stationId: match.params.station_id,
		sites: store.rootState.get('sites'),
		stationAqiData: store.rootState.getIn([
			`aqi`,
			`site_readings`,
			`${match.params.station_id}`
		]),
		overall_aqi: store.rootState.getIn(['aqi', 'current', 'Index']),
		overall_status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		overall_title: store.rootState.getIn(['aqi', 'aqi_strings', 'title']),
		state: state
	};
};

export default withRouter(connect(mapStateToProps)(StationMainPanel));
