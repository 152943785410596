import React from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../img/tooltip.svg';

const TopPollutant = ({
	pollutantId,
	pollutantName,
	pollutantVal,
	pollutantUnit,
	status,
	pollutant_avg
}) => {
	return (
		<div className='top-pollutant'>
			<h2>Top Pollutant</h2>
			<p>{pollutantName}</p>
			<div className='pollutant-id-val'>
				<p className={`pollutant-id ${status}`}>{pollutantId}</p>
			</div>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		pollutantId: store.rootState.getIn(['aqi', 'current', 'ParameterName']),
		pollutantName: store.rootState.getIn(['aqi', 'pollutant_strings', 'name']),
		pollutantUnit: store.rootState.getIn(['aqi', 'pollutant_strings', 'unit']),
		pollutantVal: store.rootState.getIn(['aqi', 'current', 'MovingAverage']),
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		pollutant_avg: store.rootState.getIn(['aqi', 'pollutant_strings', 'avg'])
	};
};

export default connect(mapStateToProps)(TopPollutant);
