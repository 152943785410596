import React, { useEffect, useReducer } from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import { fromJS } from 'immutable';

import { Tabs, Tab } from 'react-bootstrap';
import StationMainPanel from '../components/StationMainPanel';
import PollutantBreakdownPanel from '../components/PollutantBreakdownPanel';
import HistoryGraphPanel from '../components/HistoryGraphPanel';

const initialState = {
	dataStatus: 'prefetch',
	dataError: null,
	site: {},
	readings: [],
	toxics: []
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'fetch':
			return { ...state, dataStatus: 'fetching' };
		case 'error':
			return {
				...state,
				dataStatus: 'error',
				dataError: action.payload
			};
		case 'loaded':
			const {
				payload: {
					site,
					parameters,
					readings: { readings, toxics },
					aqi
				}
			} = action;

			return {
				dataStatus: 'loaded',
				site,
				parameters,
				readings,
				toxics,
				aqi
			};
		default:
			throw new Error();
	}
};

export default function StationView(props) {
	let active = 'pollutants';
	let baseURL = props.location.pathname
		.replace('/pollutants', '')
		.replace('/toxics', '');
	let tabURL = props.location.pathname.match(/([^\/]*)\/*$/)[1];

	if (tabURL == 'toxics') {
		active = 'toxics';
	}

	let isPollutant =
		active == 'pollutants' || tabURL == 'pollutants' ? true : false;
	let isToxic = active == 'toxics' || tabURL == 'toxics' ? true : false;

	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		let tabURL = props.location.pathname.match(/([^\/]*)\/*$/)[1];

		if (tabURL != 'toxics' && tabURL != 'pollutants') {
			window.scrollTo(0, 0);
		}
	}, [props.location]);

	useEffect(() => {
		const fetchReadings = () => {
			dispatch({ type: 'fetch' });
			NProgress.start();
			axios
				.get(
					`${process.env.API_BASE_URL}/sites/${props.match.params.station_id}/details`
				)
				.then(res => {
					dispatch({
						type: 'loaded',
						payload: res.data.data
					});
					NProgress.done();
				})
				.catch(err => {
					dispatch({ type: 'error', payload: err });
					console.error(err);
					NProgress.done();
				});
		};

		fetchReadings();
	}, [props.match.params.station_id]);

	const { site, parameters } = state;

	return (
		<>
			<StationMainPanel state={state} />
			<span className={status ? `tabs-wrap ${status}` : 'tabs-wrap na'}>
				<Tabs
					activeKey={active}
					defaultActiveKey={active}
					onSelect={key => {
						props.history.push(`${baseURL}/${key}`);
					}}
				>
					<Tab eventKey='pollutants' title='Pollutants' className='foo' />
					{site.has_toxics && <Tab eventKey='toxics' title='toxics' />}
				</Tabs>
			</span>
			{isPollutant && (
				<PollutantBreakdownPanel
					readings={state.readings}
					parameters={fromJS(parameters)}
				/>
			)}
			{site.has_toxics && isToxic && (
				<PollutantBreakdownPanel
					readings={state.toxics}
					parameters={fromJS(parameters)}
					isToxic
				/>
			)}

			<HistoryGraphPanel stationId={props.match.params.station_id} />
		</>
	);
}
