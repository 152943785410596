import React from 'react';
import {
	VictoryLine,
	VictoryChart,
	VictoryBar,
	VictoryPie,
	VictoryAnimation,
	VictoryLabel,
	VictoryStack,
	VictoryTheme,
	VictoryArea,
	VictoryAxis
} from 'victory';
import {
	Button,
	Card,
	Collapse,
	Dropdown,
	DropdownButton,
	Navbar,
	Nav,
	NavDropdown
} from 'react-bootstrap';

import StationMap from '../components/StationMap';

import ElderlyIcon from '../img/elderly_icon.svg';
import HeartIcon from '../img/heart_icon.svg';
import RunnerIcon from '../img/runner_icon.svg';

// import GoodMarker from '../img/good_marker.svg';
// import ModerateMarker from '../img/moderate_marker.svg';

import lightbulb from '../img/lightbulb.png';
import umbrella from '../img/umbrella.png';
import kite from '../img/kite.png';
import sun from '../img/sun.png';

import scale from '../img/aqi_scale.png';

const lineDataCurrent = [
	{ month: 'Jan', reading: 53 },
	{ month: 'Feb', reading: 50 },
	{ month: 'Mar', reading: 23 },
	{ month: 'Apr', reading: 37 },
	{ month: 'May', reading: 75 },
	{ month: 'Jun', reading: 48 },
	{ month: 'Jul', reading: 50 },
	{ month: 'Aug', reading: 20 },
	{ month: 'Sep', reading: 30 },
	{ month: 'Oct', reading: 22 },
	{ month: 'Nov', reading: 28 },
	{ month: 'Dec', reading: 55 }
];

const lineDataPast = [
	{ month: 'Jan', reading: 35 },
	{ month: 'Feb', reading: 25 },
	{ month: 'Mar', reading: 47 },
	{ month: 'Apr', reading: 19 },
	{ month: 'May', reading: 40 },
	{ month: 'Jun', reading: 26 },
	{ month: 'Jul', reading: 56 },
	{ month: 'Aug', reading: 48 },
	{ month: 'Sep', reading: 80 },
	{ month: 'Oct', reading: 37 },
	{ month: 'Nov', reading: 52 },
	{ month: 'Dec', reading: 31 }
];

const barData = [
	{ day: 'm', reading: 50 },
	{ day: 't', reading: 53 },
	{ day: 'w', reading: 51 },
	{ day: 'th', reading: 60 },
	{ day: 'f', reading: 45 },
	{ day: 's', reading: 65 },
	{ day: 'su', reading: 105 },
	{ day: 'm2', reading: 54 },
	{ day: 't2', reading: 57 },
	{ day: 'w2', reading: 53 },
	{ day: 'th2', reading: 60 },
	{ day: 'f2', reading: 43 },
	{ day: 's2', reading: 64 },
	{ day: 'su2', reading: 110 }
];

const defaultPieData = [{ x: 1, y: 1 }];

const pieData = [
	{ x: 1, y: 50, opacity: 1 },
	{ x: 2, y: 450, opacity: 0 }
];

const barStackWidth = 500;
const barStackHeight = 500;
const barStackPadding = { top: 80, bottom: 80, left: 20, right: 20 };

const barStackDataA = [
	{ x: 'OZ', y: 150 },
	{ x: 'CO', y: 10 },
	{ x: 'PM10', y: 10 }
];

const barStackDataB = barStackDataA.map(point => {
	const y = 500 - point.y;
	return { ...point, y };
});

// colors
const good = '#00e400';
const moderate = '#ffff00';
const hazardous = '#7e0023';
const gray = '#e4e4e4';

export default class UIKit extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			accordionOpen: false
		};
	}

	render() {
		const { accordionOpen } = this.state;

		const sharedPieProps = {
			standalone: false,
			width: 400,
			height: 400,
			labels: () => null
		};

		return (
			<>
				<section className='color-section'>
					<h2>Colors</h2>
					<div className='colors'>
						<div className='color good'></div>
						<div className='color moderate'></div>
						<div className='color low'></div>
						<div className='color unhealthy'></div>
						<div className='color bad'></div>
						<div className='color hazardous'></div>
					</div>
				</section>
				{/* TODO: break into component */}
				<section className='tabs-section'>
					<h2>Tabs</h2>
					<div className='tab-group'>
						<div className='tab elderly-tab selected'>
							<ElderlyIcon />
						</div>
						<div className='tab health-tab'>
							<HeartIcon />
						</div>
						<div className='tab activity-tab'>
							<RunnerIcon />
						</div>
					</div>
				</section>
				{/* TODO: break into a component, if they're used in final design */}
				<section className='alerts-section'>
					<h2>Alerts</h2>
					<div className='alerts'>
						<div className='alert fact-alert'>
							<div className='alert-img'>
								<img src={lightbulb} />
							</div>
							<div className='alert-text'>
								<h5 className='alert-heading'>Fun Fact</h5>
								<p>
									Perspiciatis unde omnis iste natus error sit voluptatem
									accusantium doloremque laudantium, totam rem aperiam, eaque
									ipsa quae ab illo inventore veritatis et quasi architecto
									beatae vit
								</p>
							</div>
						</div>
						<div className='alert error-alert'>
							<div className='alert-img'>
								<img src={umbrella} />
							</div>
							<div className='alert-text'>
								<h5 className='alert-heading'>Error!</h5>
								<p>
									Perspiciatis unde omnis iste natus error sit voluptatem
									accusantium doloremque laudantium, totam rem aperiam, eaque
									ipsa quae ab illo inventore veritatis et quasi architecto
									beatae vit
								</p>
							</div>
						</div>
						<div className='alert success-alert'>
							<div className='alert-img'>
								<img src={sun} />
							</div>
							<div className='alert-text'>
								<h5 className='alert-heading'>Success!</h5>
								<p>
									Perspiciatis unde omnis iste natus error sit voluptatem
									accusantium doloremque laudantium, totam rem aperiam, eaque
									ipsa quae ab illo inventore veritatis et quasi architecto
									beatae vit
								</p>
							</div>
						</div>
						<div className='alert general-alert'>
							<div className='alert-img'>
								<img src={kite} />
							</div>
							<div className='alert-text'>
								<h5 className='alert-heading'>General Message</h5>
								<p>
									Perspiciatis unde omnis iste natus error sit voluptatem
									accusantium doloremque laudantium, totam rem aperiam, eaque
									ipsa quae ab illo inventore veritatis et quasi architecto
									beatae vit
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className='map'>
					<StationMap />
				</section>
				<section className='map-markers'>
					<div className='marker good-marker'>
						<div className='marker-label'>
							<span>50</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='27aya'
									d='M127 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='27aye'
									d='M118 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<mask id='27ayc' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M127 1226h65v65h-65z' />
									<use xlinkHref='#27aya' />
								</mask>
								<filter
									id='27ayb'
									width='101'
									height='105'
									x='109'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1275Out'
									/>
									<feGaussianBlur
										in='FeOffset1275Out'
										result='FeGaussianBlur1276Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='27ayd'
									x1='186.67'
									x2='159.35'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='#00e400' />
								</linearGradient>
								<linearGradient
									id='27ayf'
									x1='194.27'
									x2='159.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='#00e400' stopOpacity='.25' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-118 -1217)'>
									<g filter='url(#27ayb)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#27ayc")'
											xlinkHref='#27aya'
										/>
										<use fillOpacity='.2' xlinkHref='#27aya' />
									</g>
									<use fill='url(#27ayd)' xlinkHref='#27aya' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#27aya'
									/>
								</g>
								<g transform='translate(-118 -1217)'>
									<use fill='url(#27ayf)' xlinkHref='#27aye' />
								</g>
							</g>
						</svg>
					</div>
					<div className='marker moderate-marker'>
						<div className='marker-label'>
							<span>80</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='ix1ba'
									d='M218 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='ix1bb'
									d='M209 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<linearGradient
									id='ix1bc'
									x1='285.27'
									x2='250.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='#ff0' stopOpacity='.25' />
								</linearGradient>
								<mask id='ix1be' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M218 1226h65v65h-65z' />
									<use xlinkHref='#ix1ba' />
								</mask>
								<filter
									id='ix1bd'
									width='101'
									height='105'
									x='200'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1251Out'
									/>
									<feGaussianBlur
										in='FeOffset1251Out'
										result='FeGaussianBlur1252Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='ix1bf'
									x1='277.67'
									x2='250.35'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='#ff0' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-209 -1217)'>
									<use fill='url(#ix1bc)' xlinkHref='#ix1bb' />
								</g>
								<g transform='translate(-209 -1217)'>
									<g filter='url(#ix1bd)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#ix1be")'
											xlinkHref='#ix1ba'
										/>
										<use fillOpacity='.2' xlinkHref='#ix1ba' />
									</g>
									<use fill='url(#ix1bf)' xlinkHref='#ix1ba' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#ix1ba'
									/>
								</g>
							</g>
						</svg>
					</div>
					<div className='marker low-marker'>
						<div className='marker-label'>
							<span>101</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='1s7ea'
									d='M311 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='1s7eb'
									d='M302 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<linearGradient
									id='1s7ec'
									x1='378.27'
									x2='343.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='#ff7e00' stopOpacity='.25' />
								</linearGradient>
								<mask id='1s7ee' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M311 1226h65v65h-65z' />
									<use xlinkHref='#1s7ea' />
								</mask>
								<filter
									id='1s7ed'
									width='101'
									height='105'
									x='293'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1315Out'
									/>
									<feGaussianBlur
										in='FeOffset1315Out'
										result='FeGaussianBlur1316Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='1s7ef'
									x1='370.67'
									x2='343.35'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='#ff7e00' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-302 -1217)'>
									<use fill='url(#1s7ec)' xlinkHref='#1s7eb' />
								</g>
								<g transform='translate(-302 -1217)'>
									<g filter='url(#1s7ed)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#1s7ee")'
											xlinkHref='#1s7ea'
										/>
										<use fillOpacity='.2' xlinkHref='#1s7ea' />
									</g>
									<use fill='url(#1s7ef)' xlinkHref='#1s7ea' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#1s7ea'
									/>
								</g>
							</g>
						</svg>{' '}
					</div>
					<div className='marker unhealthy-marker'>
						<div className='marker-label'>
							<span>155</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='5oqga'
									d='M404 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='5oqgb'
									d='M395 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<linearGradient
									id='5oqgc'
									x1='471.27'
									x2='436.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='red' stopOpacity='.25' />
								</linearGradient>
								<mask id='5oqge' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M404 1226h65v65h-65z' />
									<use xlinkHref='#5oqga' />
								</mask>
								<filter
									id='5oqgd'
									width='101'
									height='105'
									x='386'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1411Out'
									/>
									<feGaussianBlur
										in='FeOffset1411Out'
										result='FeGaussianBlur1412Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='5oqgf'
									x1='463.67'
									x2='436.35'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='red' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-395 -1217)'>
									<use fill='url(#5oqgc)' xlinkHref='#5oqgb' />
								</g>
								<g transform='translate(-395 -1217)'>
									<g filter='url(#5oqgd)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#5oqge")'
											xlinkHref='#5oqga'
										/>
										<use fillOpacity='.2' xlinkHref='#5oqga' />
									</g>
									<use fill='url(#5oqgf)' xlinkHref='#5oqga' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#5oqga'
									/>
								</g>
							</g>
						</svg>{' '}
					</div>
					<div className='marker bad-marker'>
						<div className='marker-label'>
							<span>206</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='ycjya'
									d='M496 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='ycjyb'
									d='M487 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<linearGradient
									id='ycjyc'
									x1='563.27'
									x2='528.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='#99004c' stopOpacity='.25' />
								</linearGradient>
								<mask id='ycjye' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M496 1226h65v65h-65z' />
									<use xlinkHref='#ycjya' />
								</mask>
								<filter
									id='ycjyd'
									width='101'
									height='105'
									x='478'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1347Out'
									/>
									<feGaussianBlur
										in='FeOffset1347Out'
										result='FeGaussianBlur1348Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='ycjyf'
									x1='555.67'
									x2='528.35'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='#99004c' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-487 -1217)'>
									<use fill='url(#ycjyc)' xlinkHref='#ycjyb' />
								</g>
								<g transform='translate(-487 -1217)'>
									<g filter='url(#ycjyd)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#ycjye")'
											xlinkHref='#ycjya'
										/>
										<use fillOpacity='.2' xlinkHref='#ycjya' />
									</g>
									<use fill='url(#ycjyf)' xlinkHref='#ycjya' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#ycjya'
									/>
								</g>
							</g>
						</svg>{' '}
					</div>
					<div className='marker hazardous-marker'>
						<div className='marker-label'>
							<span>500</span>
						</div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='83'
							height='85'
							viewBox='0 0 83 85'
						>
							<defs>
								<path
									id='m1yfa'
									d='M588.44 1258.345c0-17.864 14.481-32.345 32.345-32.345 17.864 0 32.345 14.481 32.345 32.345 0 17.864-14.481 32.345-32.345 32.345-17.864 0-32.345-14.481-32.345-32.345z'
								/>
								<path
									id='m1yfb'
									d='M580 1258.345c0-22.834 18.51-41.345 41.345-41.345 22.834 0 41.345 18.51 41.345 41.345 0 22.834-18.51 41.345-41.345 41.345-22.834 0-41.345-18.51-41.345-41.345z'
								/>
								<linearGradient
									id='m1yfc'
									x1='656.27'
									x2='621.35'
									y1='1175.88'
									y2='1274.61'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' stopOpacity='.25' />
									<stop offset='1' stopColor='#7e0023' stopOpacity='.25' />
								</linearGradient>
								<mask id='m1yfe' width='2' height='2' x='-1' y='-1'>
									<path fill='#fff' d='M588 1226h66v65h-66z' />
									<use xlinkHref='#m1yfa' />
								</mask>
								<filter
									id='m1yfd'
									width='102'
									height='105'
									x='570'
									y='1208'
									filterUnits='userSpaceOnUse'
								>
									<feOffset
										dy='4'
										in='SourceGraphic'
										result='FeOffset1379Out'
									/>
									<feGaussianBlur
										in='FeOffset1379Out'
										result='FeGaussianBlur1380Out'
										stdDeviation='3.2 3.2'
									/>
								</filter>
								<linearGradient
									id='m1yff'
									x1='648.11'
									x2='620.79'
									y1='1193.83'
									y2='1271.07'
									gradientUnits='userSpaceOnUse'
								>
									<stop offset='0' stopColor='#fff' />
									<stop offset='1' stopColor='#7e0023' />
								</linearGradient>
							</defs>
							<g>
								<g transform='translate(-580 -1217)'>
									<use fill='url(#m1yfc)' xlinkHref='#m1yfb' />
								</g>
								<g transform='translate(-580 -1217)'>
									<g filter='url(#m1yfd)'>
										<use
											fill='none'
											strokeOpacity='.2'
											strokeWidth='0'
											mask='url("#m1yfe")'
											xlinkHref='#m1yfa'
										/>
										<use fillOpacity='.2' xlinkHref='#m1yfa' />
									</g>
									<use fill='url(#m1yff)' xlinkHref='#m1yfa' />
									<use
										fill='#fff'
										fillOpacity='0'
										stroke='#fff'
										strokeMiterlimit='50'
										strokeWidth='1.8'
										xlinkHref='#m1yfa'
									/>
								</g>
							</g>
						</svg>{' '}
					</div>
				</section>
				<section className='scale'>
					<h2>Scale</h2>
					<img src={scale} />
				</section>
				<section className='pie'>
					<h2>Pie Chart</h2>
					<svg viewBox='0 0 400 400' width='100%' height='100%'>
						<VictoryPie
							{...sharedPieProps}
							data={defaultPieData}
							radius={130}
							innerRadius={120}
							style={{
								data: { fill: 'gray', opacity: d => 0.3 }
							}}
						/>
						<VictoryPie
							{...sharedPieProps}
							animate={{ duration: 1000 }}
							data={pieData}
							radius={140}
							innerRadius={110}
							style={{
								data: {
									fill: good,
									opacity: d => d.opacity
								}
							}}
						/>
						<VictoryAnimation duration={1000} data={pieData}>
							{newProps => {
								return (
									<VictoryLabel
										textAnchor='middle'
										verticalAnchor='middle'
										x={200}
										y={200}
										text={50}
										style={{ fontSize: 45 }}
									/>
								);
							}}
						</VictoryAnimation>
					</svg>
				</section>
				{/*
                looks like this got dropped in the new design

                <section className="pollutant-bars">
                    <h2>Bar Graphs</h2>
                    <svg viewBox="0 0 500 500" width="50%" height="50%">
                        <VictoryStack horizontal
                            standalone={false}
                            domain={{ y: [500, 0] }}
                            style={{ data: { width: 10 }, labels: { fontSize: 11 } }}
                        >
                            <VictoryBar
                                style={{ data: { fill: gray, strokeLinejoin: "round", strokeWidth: 10 } }}
                                data={[{ x: "OZ", y: 0 }, { x: "CO", y: 490 }, { x: "PM10", y: 480 }]}
                                labels={(data) => (data.y)}
                            />
                            <VictoryBar
                                style={{ data: { fill: good, strokeLinejoin: "round", strokeWidth: 10 } }}
                                data={[{ x: "OZ", y: 500 }, { x: "CO", y: 10 }, { x: "PM10", y: 20 }]}
                                labels={(data) => (data.x)}
                            />
                        </VictoryStack>

                        <VictoryAxis
                            style={{
                                axis: { stroke: "transparent" },
                                ticks: { stroke: "transparent" },
                                tickLabels: { fontSize: 11, fill: "black" }
                            }}
                            tickLabelComponent={<VictoryLabel x={250} textAnchor="middle"/>}
                            tickValues={dataA.map((point) => point.x).reverse()}
                            />
                    </svg>
                </section> */}
				<section className='line-chart'>
					<h2>Line Chart</h2>
					<VictoryChart
						minDomain={{ y: 0 }}
						theme={VictoryTheme.material}
						className='line-chart'
						width={500}
						height={250}
					>
						<VictoryArea
							interpolation='natural'
							style={{ data: { fill: gray } }}
							data={lineDataPast}
							x='month'
							y='reading'
						/>
						<VictoryLine
							interpolation='natural'
							style={{
								data: { stroke: good },
								parent: { border: '1px solid #ccc' }
							}}
							data={lineDataCurrent}
							x='month'
							y='reading'
						/>
						<VictoryAxis
							dependentAxis
							style={{
								axis: { stroke: 'transparent' },
								ticks: { stroke: 'transparent' },
								tickLabels: { fontSize: 8, fill: 'black' }
							}}
						/>
						<VictoryAxis
							style={{
								tickLabels: { fontSize: 8, fill: 'black' }
							}}
						/>
					</VictoryChart>
				</section>
				<section>
					<h2>Next 2 Weeks</h2>
					<VictoryChart width={400} height={150}>
						<VictoryBar
							// style={{ data: { fill: "#c43a31" } }}
							alignment='start'
							data={barData}
							x='day'
							y='reading'
							style={{
								data: {
									fill: ({ reading }) =>
										reading < 51
											? '#00e400'
											: reading < 101
											? '#ffff00'
											: reading < 151
											? '#ff7e00'
											: reading < 201
											? '#ff0000'
											: reading < 301
											? '#99004c'
											: '#7e0023'
								}
							}}
						/>
						<VictoryAxis
							style={{
								tickLabels: { fontSize: 8, fill: 'black' }
							}}
						/>
					</VictoryChart>
				</section>
				<section>
					<h2>Dropdown</h2>
					<DropdownButton className='dropdown' title='All Genres'>
						<Dropdown.Item href='#'>Action</Dropdown.Item>
						<Dropdown.Item href='#'>Adventure</Dropdown.Item>
						<Dropdown.Item href='#'>Comedy</Dropdown.Item>
						<Dropdown.Item href='#'>Dance</Dropdown.Item>
						<Dropdown.Item href='#'>Family</Dropdown.Item>
						<Dropdown.Item href='#'>Action</Dropdown.Item>
						<Dropdown.Item href='#'>Adventure</Dropdown.Item>
						<Dropdown.Item href='#'>Comedy</Dropdown.Item>
						<Dropdown.Item href='#'>Dance</Dropdown.Item>
						<Dropdown.Item href='#'>Family</Dropdown.Item>
					</DropdownButton>
				</section>
				<section className='accordion-section'>
					<h2>Accordion</h2>
					<Card className='accordion'>
						<>
							<Card.Header
								onClick={() =>
									this.setState({
										accordionOpen: !accordionOpen
									})
								}
								aria-controls='accordion-collapse'
								aria-expanded={open}
							>
								Featured
							</Card.Header>
							<Collapse in={this.state.accordionOpen}>
								<Card.Body>
									<Card.Title>
										<h3>Special title treatment</h3>
									</Card.Title>
									<Card.Text>
										With supporting text below as a natural lead-in to
										additional content.
									</Card.Text>
								</Card.Body>
							</Collapse>
						</>
					</Card>
				</section>
				<section className='typography'>
					<h1>
						Typography
						<br />
						H1 - Lato Bold, 28px, Uppercase - Section Headers
					</h1>
					<h2>
						H2 - Lato bold, 24px, mixed case - Graph/Chart labels (forecast),{' '}
					</h2>
					<h3>
						H3 - Lato regular, 18px, mixed case - Accordian section labels, �Not
						Bad!�{' '}
					</h3>
					<h4>H4 - Lato Medium, 20px, mixed case - Subheads, intro copy</h4>
					<h5>
						H5 - Lato bold, 16px, mixed case - Tool tip labels, subheads in
						alerts
					</h5>
					<h6>
						H6 - Lato regular, 14px, mixed case - Graph labels, input copy
					</h6>
					<p>
						Paragraph copy - Lato regular, 16px, mixed case.
						<br />
						ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
						veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
						ea commodo consequat. Duis aute irure dolor in reprehenderit in
						voluptate velit esse cillum dolore eu fugiat nulla pariatur.
						Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
						officia deserunt mollit anim id est laborum."
					</p>
				</section>
			</>
		);
	}
}
