import axios from 'axios';

export const GET_USER_LOCATION = 'GET_USER_LOCATION';
export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION';

export const getUserLocation = () => {
	return {
		type: GET_USER_LOCATION
	};
};

export const updateUserLocation = pos => {
	return {
		type: UPDATE_USER_LOCATION,
		pos
	};
};

export const fetchUserLocation = () => {
	return new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(pos => {
				resolve(pos);
			});
		} else {
			reject();
		}
	});
};
